<template>
  <div>
    <HeaderRow></HeaderRow>
    <el-row >
      <div class="tooltip-wrap" ref="toolbarRef">
        <el-form :inline="true" :model="formline" class="form-wrap" size="mini">
          <el-form-item label="通知事件类型" class="form-input event-name">
            <el-select
              v-model="formline.event"
              placeholder="通知事件类型"
              clearable
            >
              <el-option
                v-for="es in eventSets"
                :label="es.value"
                :key="es.key"
                :value="es"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="查询用户" class="form-input user-name">
            <UserSearchInput v-model="formline.user"></UserSearchInput>
          </el-form-item>

          <el-form-item>
            <el-date-picker
              v-model="formline.datarange"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item class="app-btn-group">
            <el-button-group>
              <el-button
                type="primary"
                class="tooltip-btn search"
                @click="searchEvents"
                >查询</el-button
              >
              <el-button
                type="default"
                class="tooltip-btn reset"
                @click="onSubmitReset"
                >重置</el-button
              >
            </el-button-group>
          </el-form-item>
        </el-form>
      </div>
    </el-row>
    <!-- <el-table :data="events" border style="width: 100%">
      <el-table-column prop="event" label="通知事件类型" width="240">
      </el-table-column>
      <el-table-column prop="name" label="姓名" width="240"> </el-table-column>
      <el-table-column prop="created" label="通知时间" width="180">
      </el-table-column>
      <el-table-column prop="model" label="更新后对象" width="180">
        <template #default="scope">
          <el-popover placement="top-start" title="" trigger="hover">
            {{ scope.row }}
            <template #reference>
              <el-button
                icon="el-icon-view"
                class="app-view"
                type="text"
                circle
              >
              </el-button>
            </template>
          </el-popover>
        </template>
      </el-table-column>
    </el-table> -->
    <BaseTable
      :tableHeight="tableHeight"
      :tableColumns="tableColumns"
      :tableData="tableData"
      :loading="tableLoading"
    >
      <template #empty>
        <div class="table-nodata">
          <img src="../../../assets/noDataBg.png" alt="" srcset="" />
          <!-- <a
              href="javascript:viod(0)"
              class="btn-add"
              @click="handleAddCategory"
            >
              新增类型
            </a> -->
        </div>
      </template>
    </BaseTable>
    <div class="block" style="text-align:right">
      <el-pagination
        small
        @size-change="pageSizeChange"
        @current-change="searchEvents"
        v-model:currentPage="currentPage"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="pageSize"
        layout="sizes, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>
<script>
import {
  ref,
  reactive,
  toRefs,
  onMounted,
  onUnmounted,
  onBeforeMount,
} from "vue";
import { eventSearch, setDingEvent } from "@/utils/api";
import UserSearchInput from "@/components/UserSearchInput";
import HeaderRow from "../components/HeaderRow";
import BaseTable from "../components/BaseTable";
// import AdminModal from "../components/AdminModal";
export default {
  components: { UserSearchInput, HeaderRow, BaseTable },
  setup() {
    let currentPage = ref(1);
    let pageSize = ref(10);
    let total = ref(0);
    let eventSets = ref([]);
    const toolbarRef = ref(null);
    let formline = ref({
      event: "", //事件类型
      user: "", //对象名称
      datarange: "" // 时间范围
    });
    let events = ref([]);
    let state = reactive({
      tableData: [],
      tableLoading:false,
      tableHeight:0,
      // 表头信息
      tableColumns: [
        {
          prop: "event",
          label: "通知事件类型",
          columnKey: "event",
          filterMultiple: false,
          minWidth: "150px",
          fixed: true,
        },
        {
          prop: "name",
          label: "姓名",
          minWidth: "180px",
        },
        {
          prop: "created",
          label: "通知时间",
          minWidth: "150px",
        },
        {
          prop: "strModel",
          label: "更新后对象",
          minWidth: "150px",
          describe: true,
        },
      ],
      onSubmitReset() {
        formline.value.event="", //事件类型
        formline.value.user="", //用户 
        formline.value.datarange=[], //时间范围
        searchEvents();
      },
      getHeight() {
        const toolbarHeight = toolbarRef.value.offsetHeight;
        state.tableHeight = window.innerHeight - toolbarHeight - 210;
      },
    });
    // 查询通讯录事件
    function searchEvents() {
      state.tableLoading=true
      let request = {
        event: formline.value.event.key,
        modelId: formline.value.user ? formline.value.user.id : "",
        pageNo: currentPage.value,
        pageSize: pageSize.value,
      };
        console.log('formValue',JSON.parse(JSON.stringify(formline.value)))
        console.log('requestValue',JSON.parse(JSON.stringify(request)))
      if (formline.value.datarange && formline.value.datarange.length == 2) {
        Object.assign(request, {
          start: formline.value.datarange[0],
          end: formline.value.datarange[1],
        });
      }
      eventSearch(request).then((res) => {
        // events.value = res.data.list;
        state.tableLoading=false
        state.tableData = res.data.list.map((v, i) => {
          return {
            strModel: JSON.stringify(v.model),
            ...v,
          };
        });
        total.value = res.data.total;
      });
    }
    // 通讯事件类型options
    function dropEventSet() {
      setDingEvent().then((res) => {
        eventSets.value = res.data;
      });
    }
    function pageSizeChange(ps) {
      pageSize.value = ps;
      searchEvents();
    }
    onBeforeMount(() => {
      dropEventSet();
      searchEvents();
    });
    onMounted(() => {
      state.getHeight();
      window.addEventListener("resize", state.getHeight);
    });
    onUnmounted(() => {
      window.removeEventListener("resize", state.getHeight);
    });
    const refData = toRefs(state);
    return {
      ...refData,
      currentPage,
      pageSize,
      total,
      eventSets,
      formline,
      events,
      searchEvents,
      dropEventSet,
      pageSizeChange,
      toolbarRef,
    };
  },
  // mounted() {
  //   this.dropEventSet();
  //   this.searchEvents();
  // },
};
</script>

<style lang="scss" scoped>
@import "@/styles/element-ui.scss";
// tooltip 工具栏
.tooltip-wrap {
  width: 100%;
  height: 40px;
  background: #ffffff;
  // display: flex;
  align-items: center;
  padding: 36px 0 36px 36px;
  background-color: #fff;
  @media only screen and (min-width: 560px) and (max-width: 1400px) {
    height: 170px;
  }
  &::v-deep(.el-form-item) {
    margin-right: 30px;
    margin-bottom: 30px;
  }
  // 工具栏label字体
  &::v-deep(.el-form-item__label) {
    font-size: 18px;
    font-family: SourceRegular;
    font-weight: 400;
    color: #262626;
    line-height: 50px;
  }
  &::v-deep(.el-form-item__content) {
    // 工具栏input框
    .el-select {
      .select-trigger {
        .el-input {
          .el-input__inner {
            height: 50px;
          }
        }
      }
    }
    .el-range-editor {
      height: 50px;
      .el-range-separator {
        display: flex;
        align-items: center;
        height: 100%;
      }
    }
  }

  .app-btn-group {
    margin-left: 44px;
    @media only screen and (min-width: 560px) and (max-width: 1400px) {
      margin-left: 0;
    }
    // 按钮组间距
    &::v-deep(.el-form-item__content) {
      line-height: 0;
    }
    // 查询重置按钮组
    .tooltip-btn {
      width: 100px;
      height: 50px;
      font-family: SourceNormal;
      font-weight: 400;
      font-size: 18px;
      &::v-deep(.el-form-item__content) {
        line-height: 50px;
      }
      &.search {
        background: #227fff;
        color: #ffffff;
      }
      &.reset {
        background: #fff;
        color: #7d7d7d;
        &:hover {
          color: #227fff;
        }
      }
    }
  }
}

.table-nodata {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  img {
    width: 412px;
    height: 235px;
  }
}
</style>
